import React, { useState, useRef, useEffect } from 'react';
import './SeriesQianji.less';

import { Slider, Products } from '../../components';
import { qianjiItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/series/qianji/info_1.jpg';
import infoImg_3 from '../../assets/series/qianji/info_3.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};


const SeriesQianjiPage: React.FC = () => {
    const [sliderValue, setSliderValue] = useState(0);
    const intervalRef = useRef < any | null > (null);
    let count: number = 0;
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (count <= 100) {
                setSliderValue(s => s + 0.2);
                count += 0.2;
            } else {
                setSliderValue(0);
                count = 0;
            }
            // console.log(count);
        }, 100);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleOnchange = (s: number, index: number) => {
        // console.log(s, index);
        setSliderValue(s);
    }

    const handleSliderClick = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <div className="seriesQianjiPage">
            <div className="seriesQianjiPage__info">
                <img  src={infoImg_1} alt="" className="seriesQianjiPage__info-img"/>
            </div>
            <div className="seriesQianjiPage__middleBg">
                   <div className="seriesQianjiPage__infoContent">
                     <motion.div
                          ref={ref}
                          variants={boxVariant}
                          initial="hidden"
                          animate={control}
                     >
                     <div className="seriesQianjiPage__infoContent-tl">千机蝶变</div>
                     </motion.div>
                       <motion.div
                      ref={ref}
                      variants={boxVariant1}
                      initial="hidden"
                      animate={control}
                       >
                     <div className="seriesQianjiPage__infoContent-text">
                         <p>掀起剧变翅膀</p>
                         <p>漫卷季节的更替</p>
                         <p>不可言说的秘密</p>
                         <p>是摄取灵魂的女神</p>
                         <p>也是穿越梦境现实的精灵</p> 
                         <p>将自然生物造型融合液态金属质感</p>  
                         <p>聚焦于形态雕琢及工艺刻画</p> 
                         <p>打造轻夸张未来感珠宝型格</p> 
                         <p>传达向往无拘无束的态度宣言</p> 
                     </div>
                     </motion.div> 
                </div>
                   <div className="seriesQianjiPage__product">
                    <div className="seriesQianjiPage__productWrap" onClick={handleSliderClick}>  
                        <Products 
                           data={qianjiItems}
                           value={sliderValue}
                           multiple={1}
                         />
                        <Slider 
                           value={sliderValue}
                           controlClass="control_qianji"
                           onchange={handleOnchange}
                        />
                   </div>
            </div>
            </div>
         
            <div className="seriesQianjiPage__info" style={{marginTop: 0}}>
                <img  src={infoImg_3} alt="" className="seriesQianjiPage__info-img"/>
                  <div className="seriesQianjiPage__infoThree">
                    <p>以蝴蝶作为灵感缪斯</p>
                    <p>将自然生物造型融合液态金属质感</p>
                    <p>链条与蝴蝶的灵动造型交相辉映</p>
                    <p>多种姿态的银蝶在颈间翩然舞动</p>   
                    <p>寄寓了自由的梦想  感受与生俱来的力量</p>
                </div>
            </div>
           
         
        </div>
    )
}

export default SeriesQianjiPage;