import React, { useEffect } from 'react';
import './Shop.less';


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/shop/info_1.png';
import infoImg_2 from '../../assets/shop/info_2.png';
import qrcodeImg from '../../assets/shop/qrcode.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const ShopPage: React.FC = () => {

 const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <div className="shopPage">
            <div className="shopPage_info">
                <img  src={infoImg_1} alt="" className="shopPage_info-img"/>
                <div className="shopPage_title">
                    <div className="shopPage_title-cn">线下实体店</div>
                    <div className="shopPage_title-en">OFF-LINE STORE</div>
                </div>
            </div>
            <div className="shopPage_info" style={{marginTop: "-10px"}}>
                <img  src={infoImg_2} alt="" className="shopPage_info-img"/>
            </div>
            <div className="shopPage_foot">
                <div className="shopPage_shops">
                 <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">深圳</div>
                             <div className="shopPage_shopsLeft-en">SHEN ZHEN</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳卓悦汇店</div>
                                 <div className="shopPage_shopItem-address">深圳市福田区中康路卓悦汇商场</div>
                             </div>
                              <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳星河COCO Park福田店</div>
                                 <div className="shopPage_shopItem-address">深圳市福田区福华三路268号星河COCO Park北区B1-J06-J07</div>
                             </div>
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳KALEDO店</div>
                                 <div className="shopPage_shopItem-address">深圳市南山区保利文化广场KALEDO商场L1-A</div>
                             </div>
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳宝安大仟里购物中心店</div>
                                 <div className="shopPage_shopItem-address">深圳市宝安区海城路3号大仟里购物中心一楼L149</div>
                             </div>
                         </div>
                     </div>
                    </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >

                     <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">郑州</div>
                             <div className="shopPage_shopsLeft-en">ZHENG ZHOU</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">郑州正弘城店</div>
                                 <div className="shopPage_shopItem-address">郑州市金水区花园路126号正弘城LB-M10</div>
                             </div>
                         </div>
                     </div>
                       <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">杭州</div>
                             <div className="shopPage_shopsLeft-en">HANG ZHOU</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">杭州银泰百货西湖店</div>
                                 <div className="shopPage_shopItem-address">杭州市上城区延安路98号银泰百货(西湖店)一楼1B-1054</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">义乌</div>
                             <div className="shopPage_shopsLeft-en">YIWU</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">银泰百货义乌店</div>
                                 <div className="shopPage_shopItem-address">金华市义乌市工人西路15号银泰百货义乌店二楼2022</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">嘉兴</div>
                             <div className="shopPage_shopsLeft-en">JAXING</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">南湖万达店</div>
                                 <div className="shopPage_shopItem-address">嘉兴市南湖区万达广场1036号</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">绍兴</div>
                             <div className="shopPage_shopsLeft-en">SHAO XING</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">柯桥万达店</div>
                                 <div className="shopPage_shopItem-address">绍兴市柯桥区万达广场1039号</div>
                             </div>
                         </div>
                     </div>
                      </motion.div>
                        <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             &nbsp;
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_qrcode">
                                 <img  src={qrcodeImg} alt="" className="shopPage_qrcode-img"/>
                                 <div className="shopPage_qrcode-text">更多门店扫码开启 </div>
                             </div>
                         </div>
                     </div>
                </div>
            </div>
        </div>

    )
}

export default ShopPage;