import React, { useState, useRef, useEffect } from 'react';
import './SeriesAnye.less';
import { Slider, Products } from '../../components';
import { anyeItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/series/anye/info_1.jpg';
import infoImg_2 from '../../assets/series/anye/info_2.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const SeriesAnyePage: React.FC = () => {
    const [sliderValue, setSliderValue] = useState(0);
    const intervalRef = useRef < any | null > (null);
    let count: number = 0;
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (count <= 100) {
                setSliderValue(s => s + 0.2);
                count += 0.2;
            } else {
                setSliderValue(0);
                count = 0;
            }
            // console.log(count);
        }, 100);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleOnchange = (s: number, index: number) => {
        // console.log(s, index);
        setSliderValue(s);
    }

    const handleSliderClick = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <div className="seriesAnyePage">
            <div className="seriesAnyePage__info">
                <img  src={infoImg_1} alt="" className="seriesAnyePage__info-img"/>
            </div>
           <div className="seriesAnyePage__info" style={{marginTop: "-10px"}}>
                <img  src={infoImg_2} alt="" className="seriesAnyePage__info-img"/>
                <div className="seriesAnyePage__infoContent">
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesAnyePage__infoContent-tl">暗夜荆棘</div>
                   </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesAnyePage__infoContent-text">
                         <p>生于丛林深处</p>
                         <p>长于荒野之上</p>
                         <p>有着不羁与坚韧</p>
                         <p>还有柔韧与锋芒</p>
                         <p>&nbsp;</p>   
                         <p>藤蔓交织，灵魂苏醒</p> 
                         <p>突破桎梏，肆意生长 </p>  
                         <p>感受现代主义反叛美学</p> 
                         <p>打开荆棘世界</p> 
                         <p>&nbsp;</p>
                         <p>荆棘丛生，荆棘重生</p> 
                         <p>不愿屈服，野蛮生长</p>      
                     </div>
                     </motion.div> 
                </div>

                 <div className="seriesAnyePage__infoTwo">
                    <p>危险又迷人的荆棘</p>
                    <p>由荆与棘两组植物藤条交错缠绕生长而成</p>
                    <p>象征着顽强的生命力</p>
                     <p>&nbsp;</p>
                    <p>「暗夜荆棘」系列主张勇敢与自由</p>   
                    <p>诠释新生代肆意生长</p>
                    <p>知畏无畏的生活态度</p> 
                </div>
            </div>
           
            <div className="seriesAnyePage__product">
                   <div className="seriesAnyePage__productWrap" onClick={handleSliderClick}>  
                        <Products 
                           data={anyeItems}
                           value={sliderValue}
                           multiple={3}
                         />
                        <Slider 
                           value={sliderValue}
                           controlClass="control_anye"
                           onchange={handleOnchange}
                        />
                   </div>
            </div>
        </div>
    )
}

export default SeriesAnyePage;