import React, { useEffect } from 'react';
import { Outlet } from "react-router";
import { Header, Footer } from '../components';
import './MainLayout.less';

const MainLayout: React.FC = () => {

    return(
        <div className="mainLayout">
              <Header /> 
              <div className="mainContent">
                <Outlet />
              </div>
            {/* <Footer /> */}
        </div>
    )
}

export default MainLayout;