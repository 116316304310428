import React from 'react';
import './SearchEmpty.less';

import infoImg from '../../assets/contact/info.png';

const SearchEmptyPage: React.FC = () => {

    return (
        <div className="searchEmptyPage">
            <div className="searchEmptyPage_info">
                <img  src={infoImg} alt="" className="searchEmptyPage_info-img"/>
            </div>
            <div className="searchEmptyPage_text">查询不到相关数据...</div>
        </div>

    )
}

export default SearchEmptyPage;