import React, { useState, useRef, useEffect } from 'react';
import './SeriesYihuo.less';

import { Slider, Products } from '../../components';
import { yihuoItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoTextImg from '../../assets/series/yihuo/info_text.png';
import infoImg_1 from '../../assets/series/yihuo/info_1.jpg';
import infoImg_2 from '../../assets/series/yihuo/info_2.jpg';
import infoImg_3 from '../../assets/series/yihuo/info_3.jpg';
import infoImg_5 from '../../assets/series/yihuo/info_5.jpg';
import infoImg_7 from '../../assets/series/yihuo/info_7.jpg';

import itemImg_1 from '../../assets/series/yihuo/item_1.jpg';
import itemImg_2 from '../../assets/series/yihuo/item_2.jpg';
import itemImg_3 from '../../assets/series/yihuo/item_3.jpg';
import itemImg_4 from '../../assets/series/yihuo/item_4.jpg';
import itemImg_5 from '../../assets/series/yihuo/item_5.jpg';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};


const SeriesYihuoPage: React.FC = () => {
    const [sliderValue, setSliderValue] = useState(0);
    const intervalRef = useRef < any | null > (null);
    let count: number = 0;
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (count <= 100) {
                setSliderValue(s => s + 0.2);
                count += 0.2;
            } else {
                setSliderValue(0);
                count = 0;
            }
            // console.log(count);
        }, 100);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleOnchange = (s: number, index: number) => {
        // console.log(s, index);
        setSliderValue(s);
    }

    const handleSliderClick = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);
    return (
        <div className="seriesYihuoPage">
            <div className="seriesYihuoPage__info">
                <img  src={infoImg_1} alt="" className="seriesYihuoPage__info-img"/>
                <div className="seriesYihuoPage__infoContent">
                 <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesYihuoPage__infoContent-tl">
                        <img  src={infoTextImg} alt="" className="seriesYihuoPage__infoContent-tlImg"/>
                     </div>
                     </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesYihuoPage__infoContent-text">
                         <p>萧寂的山野中</p>
                         <p>孕育了一个追逐太阳的民族</p>
                         <p>古城的黄漆器的彩和火把的光</p>
                         <p>渲染出彝族丰富而浓烈的色彩</p>   
                         <p>彝族世代与火共生</p>   
                         <p>将太阳与火焰化作图腾</p>   
                         <p>彝族世代与火共生</p>   
                         <p>指引光明 驱散晦暗</p>   
                         <p>稳重又热烈 绚烂又辉煌</p>   
                     </div>
                      <div className="seriesYihuoPage__infoContent-text">
                         <p>金与银的糅炼捶打</p>
                         <p>讲述了太阳火与索马花的故事</p>
                         <p>民族美学与abcd啊啵呲嘚的交织未完待续</p>
                     </div>
                 </motion.div>
                </div>
            </div>
            <div className="seriesYihuoPage__info" style={{marginTop: "-10px"}}>
                <img  src={infoImg_2} alt="" className="seriesYihuoPage__info-img"/>
                <div className="seriesYihuoPage__middleTitle">
                  几近消逝的千年非遗工艺—错金银工艺
                </div>
            </div>
            <div className="seriesYihuoPage__info">
                <img  src={infoImg_3} alt="" className="seriesYihuoPage__info-img"/>
                <div className="seriesYihuoPage__infoThree">
                    <p>《彝火生花》系列作品</p>
                    <p>秉承着上古春秋时期</p>
                    <p>中国最古老的“错金银”精工技法</p>
                    <p>精心打造而成</p>   
                    <p>在材质方面，选用了最接近太阳色感的18K金</p>   
                    <p>以及象征无垠大地的S925银</p>   
                    <p>通过錾刻、嵌金、锤凿等精细工艺</p>   
                    <p>使两种金属完美融合，呈现精湛图案</p>   
                    <p>工艺复杂且难度极高</p>   
                </div>
            </div>
            <div className="seriesYihuoPage__infoFourWrap">
                   <div className="content-row">
                       <div className="seriesYihuoPage__items">
                            <div className="seriesYihuoPage__itemCol">
                                 <img  src={itemImg_1} alt="" className="seriesYihuoPage__itemCol-img"/>
                                 <div className="seriesYihuoPage__itemComtent">
                                     <div className="seriesYihuoPage__itemTl">退火提韧</div>
                                     <div className="seriesYihuoPage__itemDesc">退火是对金属进行加热处理，使其变得柔软易于加工，提高其韧性和延展性。</div>
                                 </div>  
                            </div>
                            <div className="seriesYihuoPage__itemCol">
                                 <img  src={itemImg_2} alt="" className="seriesYihuoPage__itemCol-img"/>
                                 <div className="seriesYihuoPage__itemComtent">
                                     <div className="seriesYihuoPage__itemTl">嵌底镂金</div>
                                     <div className="seriesYihuoPage__itemDesc">在阴刻阳錾的基础上，将金银等贵重金属片嵌入凹槽，形成鲜明的对比。</div>
                                 </div>  
                            </div>
                            <div className="seriesYihuoPage__itemCol">
                                 <img  src={itemImg_3} alt="" className="seriesYihuoPage__itemCol-img"/>
                                 <div className="seriesYihuoPage__itemComtent">
                                     <div className="seriesYihuoPage__itemTl">纹饰雕琢</div>
                                     <div className="seriesYihuoPage__itemDesc">在金银片嵌入凹槽后，对纹饰进行精细的雕琢，使其更加精致美观。</div>
                                 </div>  
                            </div>
                            <div className="seriesYihuoPage__itemCol">
                                 <img  src={itemImg_4} alt="" className="seriesYihuoPage__itemCol-img"/>
                                 <div className="seriesYihuoPage__itemComtent">
                                     <div className="seriesYihuoPage__itemTl">严丝合缝</div>
                                     <div className="seriesYihuoPage__itemDesc">将金银与其他金属紧密地连接在一起，需要工匠非常细心耐心，细节都处理得恰到好处。</div>
                                 </div>  
                            </div>
                            <div className="seriesYihuoPage__itemCol">
                                 <img  src={itemImg_5} alt="" className="seriesYihuoPage__itemCol-img"/>
                                 <div className="seriesYihuoPage__itemComtent">
                                     <div className="seriesYihuoPage__itemTl">阴刻阳錾</div>
                                     <div className="seriesYihuoPage__itemDesc">工匠通过在金属表面进行阴刻或阳錾，创造出浅凹立体的纹饰或图案。</div>
                                 </div>  
                            </div>
                       </div>
                   </div>
            </div>
            <div className="seriesYihuoPage__info">
                <img  src={infoImg_5} alt="" className="seriesYihuoPage__info-img"/>
                <div className="seriesYihuoPage__infoFive">
                    <p>精神化身在图腾之上</p>
                    <p>汲取彝族寓意希望的「太阳火」与</p>   
                    <p>并结合象征团结和收获的八角图成形</p>   
                    <p>将彝族符号与信仰倾注于作品之上</p>   
                    <p>&nbsp;</p>   
                    <p>古老技艺与崇高之意</p>   
                    <p>以及文化相融的印记</p>
                    <p>--这些都是我们想要糅合在珠宝里的意象</p> 
                </div>
            </div>
            <div className="seriesYihuoPage__product">
                  <div className="seriesYihuoPage__productWrap" onClick={handleSliderClick}>  
                        <Products 
                           data={yihuoItems}
                           value={sliderValue}
                           multiple={3}
                         />
                        <Slider 
                           value={sliderValue}
                           controlClass="control_yihuo"
                           onchange={handleOnchange}
                        />
                   </div>
           </div>
             <div className="seriesYihuoPage__info">
                <img  src={infoImg_7} alt="" className="seriesYihuoPage__info-img"/>

                <div className="seriesYihuoPage__infoSeven">
                    <p>演绎流金烁银中参差生息的世界</p>
                    <p>致敬来自高原的生命自然与信仰</p>   
                     <p>&nbsp;</p>  
                    <p>登一座山</p>   
                    <p>赏一轮月</p>
                    <p>于自然中撒野，感受万物之美</p>   
                    <p>&nbsp;</p>   
                    <p>炼一寸金</p>   
                    <p>铸一方银</p>
                    <p>于金银中感知，工艺糅合之妙</p> 
                </div>
            </div>
        </div>
    )
}

export default SeriesYihuoPage;