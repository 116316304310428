import React, { useState, useRef, useEffect } from 'react';
import './SeriesLiehuang.less';

import { Slider, Products } from '../../components';
import { liehuangItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/series/liehuang/info_1.jpg';
import infoImg_2 from '../../assets/series/liehuang/info_2.png';
import infoImg_4 from '../../assets/series/liehuang/info_4.png';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};


const SeriesLiehuangPage: React.FC = () => {
    const [sliderValue, setSliderValue] = useState(0);
    const intervalRef = useRef < any | null > (null);
    let count: number = 0;
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (count <= 100) {
                setSliderValue(s => s + 0.2);
                count += 0.2;
            } else {
                setSliderValue(0);
                count = 0;
            }
            // console.log(count);
        }, 100);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleOnchange = (s: number, index: number) => {
        // console.log(s, index);
        setSliderValue(s);
    }

    const handleSliderClick = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <div className="seriesLiehuangPage">
            <div className="seriesLiehuangPage__info">
                <img  src={infoImg_1} alt="" className="seriesLiehuangPage__info-img"/>
                 <div className="seriesLiehuangPage__infoContent">
                   <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesLiehuangPage__infoContent-tl">猎荒派对</div>
                  </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesLiehuangPage__infoContent-text">
                         <p>站在世界的尽头，光影浮华转瞬即逝，只剩残垣败瓦</p>
                         <p>风沙中夹杂着历史的沧桑与未知的低语</p>
                         <p>生命犹如一粒尘埃，在浩渺的宇宙中无处安放</p>
                         <p>凝视末日的深渊</p>   
                     </div>
                      <div className="seriesLiehuangPage__infoContent-text">
                         <p>人类总是麻木地</p>
                         <p>忘却人与星球关系之深厚</p>
                         <p>忘却大地总是以其无尽的慷慨滋养着万物</p>
                         <p>不断孕育出新的文明与希望</p>
                     </div>
                     <div className="seriesLiehuangPage__infoContent-text">
                         <p>我们尝试探寻一种永恒地能量</p>
                         <p>支撑我们拨开迷雾</p>
                     </div>
                      <div className="seriesLiehuangPage__infoContent-text">
                         <p>如末日深渊一抹闪烁的光亮</p>
                         <p>重生之念就此燎原</p>
                         <p>在万物复苏之际</p>
                         <p>我们感悟自然 拾取生息之壤</p>
                         <p>以诚挚之心打造</p>
                     </div> 
                     </motion.div> 
                </div>
            </div>
            <div className="seriesLiehuangPage__info" style={{marginTop: "-10px"}}>
                <img  src={infoImg_2} alt="" className="seriesLiehuangPage__info-img"/>
               
                 <div className="seriesLiehuangPage__infoTwo">
                    <p>在万物复苏之际 我们感悟自然</p>
                    <p>拾取生息之壤</p>
                    <p>啊啵呲嘚以诚挚之心打造「猎荒派对」系列</p>
                </div>
        
              
                <div className="seriesLiehuangPage__infoThree">
                    <p>注入突破时空的先锋态度</p>
                    <p>解构并循迹未来主义美学</p>
                    <p>灵感来源于在废土中锈斑累累的工业残骸</p>
                    <p>与未来科技美学的碰撞</p>
                    <p>突破传统珠宝首饰的设计风格和工艺壁垒</p>
                    <p>以夸张的造型打造先锋未来美学</p>
                </div>
               
            </div>
            <div className="seriesLiehuangPage__product">
                    <div className="seriesLiehuangPage__productWrap" onClick={handleSliderClick}>  
                        <Products 
                           data={liehuangItems}
                           value={sliderValue}
                           multiple={3}
                         />
                        <Slider 
                           value={sliderValue}
                           controlClass="control_liehuang"
                           onchange={handleOnchange}
                        />
                   </div>
            </div>
            <div className="seriesLiehuangPage__info" style={{marginTop: "-40px"}}>
                <img  src={infoImg_4} alt="" className="seriesLiehuangPage__info-img"/>
                 <div className="seriesLiehuangPage__infoFour">
                    <p>斑驳磨旧工艺结合天体符文图腾</p>
                    <p>如同一部微型的天文学史缩影</p>
                    <p>精银篆刻展现极致机能美学</p>
                    <p>借物表达秉性中的棱角</p>
                    <p>我不想圆滑，更不愿藏起锋芒</p>
                </div>
            </div> 
        </div>
    )
}

export default SeriesLiehuangPage;