export const relianItems = [{
	name: '征服者项链',
	pic: require('../assets/series/relian/p_1.jpg'),
	bigPic: require('../assets/series/relian/p_2.jpg')
},{
	name: '骑士荣耀项链',
	pic:  require('../assets/series/relian/p_3.jpg'),
	bigPic: require('../assets/series/relian/p_4.jpg')
},{
	name: '掌舵者手链',
	pic:  require('../assets/series/relian/p_5.jpg'),
	bigPic: require('../assets/series/relian/p_6.jpg')
},{
	name: '开拓者项链',
	pic:  require('../assets/series/relian/p_7.jpg'),
	bigPic: require('../assets/series/relian/p_8.jpg')
},{
	name: '摩登环项链',
	pic:  require('../assets/series/relian/p_9.jpg'),
	bigPic: require('../assets/series/relian/p_10.jpg')
},{
	name: '复古工业环项链',
	pic:  require('../assets/series/relian/p_11.jpg'),
	bigPic: require('../assets/series/relian/p_12.jpg')
},{
	name: '云中连理项链',
	pic:  require('../assets/series/relian/p_13.jpg'),
	bigPic: require('../assets/series/relian/p_14.jpg')
},{
	name: '山川时悦项链',
	pic:  require('../assets/series/relian/p_15.jpg'),
	bigPic: require('../assets/series/relian/p_16.jpg')
}]


export const anyeItems = [{
	name: '知畏无畏链坠',
	pic: require('../assets/series/anye/p_1.jpg'),
    bigPic: require('../assets/series/anye/p_2.jpg')
},{
	name: '刺影耳圈',
	pic: require('../assets/series/anye/p_3.jpg'),
    bigPic: require('../assets/series/anye/p_4.jpg')
 },{
	name: '双生戒指',
	pic: require('../assets/series/anye/p_5.jpg'),
    bigPic: require('../assets/series/anye/p_6.jpg')
 },{
	name: '馥蕾手链',
	pic: require('../assets/series/anye/p_7.jpg'),
    bigPic: require('../assets/series/anye/p_8.jpg')
 },{
	name: '披靡之荆链坠',
	pic: require('../assets/series/anye/p_9.jpg'),
    bigPic: require('../assets/series/anye/p_10.jpg')
 },{
	name: '无束丛生手链',
	pic: require('../assets/series/anye/p_11.jpg'),
    bigPic: require('../assets/series/anye/p_12.jpg')
 },{
	name: '荆棘丛生耳饰',
	pic: require('../assets/series/anye/p_13.jpg'),
    bigPic: require('../assets/series/anye/p_14.jpg')
 },{
	name: '荆影不离戒指',
	pic: require('../assets/series/anye/p_15.jpg'),
    bigPic: require('../assets/series/anye/p_16.jpg')
 },{
	name: '灵动刺蔓耳饰',
	pic: require('../assets/series/anye/p_17.jpg'),
    bigPic: require('../assets/series/anye/p_18.jpg')
 },{
	name: '暗夜荆棘戒指',
	pic: require('../assets/series/anye/p_19.jpg'),
    bigPic: require('../assets/series/anye/p_20.jpg')
 },{
	name: '锋行者耳饰',
	pic: require('../assets/series/anye/p_21.jpg'),
    bigPic: require('../assets/series/anye/p_22.jpg')
 },{
	name: '瑰魅项链',
	pic: require('../assets/series/anye/p_23.jpg'),
    bigPic: require('../assets/series/anye/p_24.jpg')
 },{
	name: '无畏之荆项链',
	pic: require('../assets/series/anye/p_25.jpg'),
    bigPic: require('../assets/series/anye/p_26.jpg')
 },{
	name: '荆棘陷阱耳饰',
	pic: require('../assets/series/anye/p_27.jpg'),
    bigPic: require('../assets/series/anye/p_28.jpg')
 },{
	name: '瑰境寻香戒指',
	pic: require('../assets/series/anye/p_29.jpg'),
    bigPic: require('../assets/series/anye/p_30.jpg')
 },{
	name: '锋悦耳饰',
	pic: require('../assets/series/anye/p_31.jpg'),
    bigPic: require('../assets/series/anye/p_32.jpg')
 }
]


export const qianjiItems = [{
	name: '不落尘世耳饰',
	pic: require('../assets/series/qianji/p_1.jpg'),
    bigPic: require('../assets/series/qianji/p_2.jpg')
 },
 {
	name: '破茧戒指',
	pic: require('../assets/series/qianji/p_3.jpg'),
    bigPic: require('../assets/series/qianji/p_4.jpg')
 },
 {
	name: '蝶涌链坠',
	pic: require('../assets/series/qianji/p_5.jpg'),
    bigPic: require('../assets/series/qianji/p_6.jpg')
 },
 {
	name: '晓梦迷蝶手链',
	pic: require('../assets/series/qianji/p_7.jpg'),
    bigPic: require('../assets/series/qianji/p_8.jpg')
 },
 {
	name: '幻翩跹梦耳饰',
	pic: require('../assets/series/qianji/p_9.jpg'),
    bigPic: require('../assets/series/qianji/p_10.jpg')
 },
 {
	name: '庄生晓梦链坠',
	pic: require('../assets/series/qianji/p_11.jpg'),
    bigPic: require('../assets/series/qianji/p_12.jpg')
 },
 {
	name: '蝶光舞者戒指',
	pic: require('../assets/series/qianji/p_13.jpg'),
    bigPic: require('../assets/series/qianji/p_14.jpg')
 },
 {
	name: '浮生蝶梦链坠',
	pic: require('../assets/series/qianji/p_15.jpg'),
    bigPic: require('../assets/series/qianji/p_16.jpg')
 }
]


export const yihuoItems = [{
    name: '朝曦耳饰',
	pic: require('../assets/series/yihuo/p_1.jpg'),
    bigPic: require('../assets/series/yihuo/p_2.jpg')
 },
 {
    name: '鸿环链坠',
	pic: require('../assets/series/yihuo/p_3.jpg'),
    bigPic: require('../assets/series/yihuo/p_4.jpg')
 },
 {
    name: '苍魂戒指',
	pic: require('../assets/series/yihuo/p_5.jpg'),
    bigPic: require('../assets/series/yihuo/p_6.jpg')
 },
 {
    name: '辉熠手链',
	pic: require('../assets/series/yihuo/p_7.jpg'),
    bigPic: require('../assets/series/yihuo/p_8.jpg')
 },
 {
    name: '耀颂歌吊坠',
	pic: require('../assets/series/yihuo/p_9.jpg'),
    bigPic: require('../assets/series/yihuo/p_10.jpg')
 },
 {
    name: '索玛耳饰',
	pic: require('../assets/series/yihuo/p_11.jpg'),
    bigPic: require('../assets/series/yihuo/p_12.jpg')
 },
 {
    name: '朝晖手链',
	pic: require('../assets/series/yihuo/p_13.jpg'),
    bigPic: require('../assets/series/yihuo/p_14.jpg')
 },
 {
    name: '布托戒指',
	pic: require('../assets/series/yihuo/p_15.jpg'),
    bigPic: require('../assets/series/yihuo/p_16.jpg')
 },
 {
    name: '辉熠链坠',
	pic: require('../assets/series/yihuo/p_17.jpg'),
    bigPic: require('../assets/series/yihuo/p_18.jpg')
 },
 {
    name: '拂晓耳饰',
	pic: require('../assets/series/yihuo/p_19.jpg'),
    bigPic: require('../assets/series/yihuo/p_20.jpg')
 },
 {
    name: '熠耀链坠',
	pic: require('../assets/series/yihuo/p_21.jpg'),
    bigPic: require('../assets/series/yihuo/p_22.jpg')
 },
 {
    name: '煦韵耳饰',
	pic: require('../assets/series/yihuo/p_23.jpg'),
    bigPic: require('../assets/series/yihuo/p_24.jpg')
 },
 {
    name: '曜魄戒指',
	pic: require('../assets/series/yihuo/p_25.jpg'),
    bigPic: require('../assets/series/yihuo/p_26.jpg')
 },
 {
    name: '曜灵手链',
	pic: require('../assets/series/yihuo/p_27.jpg'),
    bigPic: require('../assets/series/yihuo/p_28.jpg')
 },
 {
    name: '联翩链坠',
	pic: require('../assets/series/yihuo/p_29.jpg'),
    bigPic: require('../assets/series/yihuo/p_30.jpg')
 },
 {
    name: '朵哈戒指',
	pic: require('../assets/series/yihuo/p_31.jpg'),
    bigPic: require('../assets/series/yihuo/p_32.jpg')
 }
]


export const liehuangItems = [{
    name: '源计划链坠',
	pic: require('../assets/series/liehuang/p_1.jpg'),
    bigPic: require('../assets/series/liehuang/p_2.jpg')
 },
 {
    name: '星际环游戒指',
	pic: require('../assets/series/liehuang/p_3.jpg'),
    bigPic: require('../assets/series/liehuang/p_4.jpg')
 },
 {
    name: '量子传感耳饰',
	pic: require('../assets/series/liehuang/p_5.jpg'),
    bigPic: require('../assets/series/liehuang/p_6.jpg')
 },
 {
    name: '科幻航线手链',
	pic: require('../assets/series/liehuang/p_7.jpg'),
    bigPic: require('../assets/series/liehuang/p_8.jpg')
 },
 {
    name: '星系迷踪链坠',
	pic: require('../assets/series/liehuang/p_9.jpg'),
    bigPic: require('../assets/series/liehuang/p_10.jpg')
 },
 {
    name: '时空骇客戒指',
	pic: require('../assets/series/liehuang/p_11.jpg'),
    bigPic: require('../assets/series/liehuang/p_12.jpg')
 },
 {
    name: '量子空间耳饰',
	pic: require('../assets/series/liehuang/p_13.jpg'),
    bigPic: require('../assets/series/liehuang/p_14.jpg')
 },
 {
    name: '星启之旅手链',
	pic: require('../assets/series/liehuang/p_15.jpg'),
    bigPic: require('../assets/series/liehuang/p_16.jpg')
 },
 {
    name: '越墟之舰吊坠',
	pic: require('../assets/series/liehuang/p_17.jpg'),
    bigPic: require('../assets/series/liehuang/p_18.jpg')
 },
 {
    name: '陨星环耳饰',
	pic: require('../assets/series/liehuang/p_19.jpg'),
    bigPic: require('../assets/series/liehuang/p_20.jpg')
 },
 {
    name: '前卫之星戒指',
	pic: require('../assets/series/liehuang/p_21.jpg'),
    bigPic: require('../assets/series/liehuang/p_22.jpg')
 },
 {
    name: '星辰之舞链坠',
	pic: require('../assets/series/liehuang/p_23.jpg'),
    bigPic: require('../assets/series/liehuang/p_24.jpg')
 },
 {
    name: '拓荒星环耳饰',
	pic: require('../assets/series/liehuang/p_25.jpg'),
    bigPic: require('../assets/series/liehuang/p_26.jpg')
 },
 {
    name: '猎荒之星戒指',
	pic: require('../assets/series/liehuang/p_27.jpg'),
    bigPic: require('../assets/series/liehuang/p_28.jpg')
 },
 {
    name: '科幻航线链坠',
	pic: require('../assets/series/liehuang/p_29.jpg'),
    bigPic: require('../assets/series/liehuang/p_30.jpg')
 },
 {
    name: '星际罗盘戒指',
	pic: require('../assets/series/liehuang/p_31.jpg'),
    bigPic: require('../assets/series/liehuang/p_32.jpg')
 }
]


