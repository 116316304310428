import React from "react";
import './Footer.less';
import { Link } from "react-router-dom";
import logoImg from "../../assets/common/logo.png";

export const Footer: React.FC = () => {

    return (
        <div className="footerContainer">
           <div className="footerContainer__row">
              <div className="footerContainer__logo">
                <img  src={logoImg} alt="" className="footerContainer__logo-img"/>
              </div>
              <div className="footerContainer__sologan">
               先 / 锋 / 潮 / 流 / 艺 / 术 / 珠 / 宝
              </div>
            </div>
        </div>
     )    
}