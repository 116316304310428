import React, { useEffect } from 'react';
import './Brand.less';


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import logoImg from "../../assets/common/logo.png";
import infoImg from '../../assets/brand/info.png';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};


const BrandPage: React.FC = () => {

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <div className="brandPage">
            <div className="brandPage_info">
                <img  src={infoImg} alt="" className="brandPage_info-img"/>
                 <div className="brandPage__infoContent">
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="brandPage__infoContent-tl">先锋潮流艺术珠宝</div>
                      <div className="brandPage__infoContent-en">ABOUT US</div>
                    </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="brandPage__infoContent-text">
                         <p>站在世界的尽头，光影浮华转瞬即逝，只剩残垣败瓦</p>
                         <p>风沙中夹杂着历史的沧桑与未知的低语</p>
                         <p>生命犹如一粒尘埃，在浩渺的宇宙中无处安放</p>
                         <p>凝视末日的深渊</p>   
                     </div>
                      <div className="brandPage__infoContent-text">
                         <p>人类总是麻木地</p>
                         <p>忘却人与星球关系之深厚</p>
                         <p>忘却大地总是以其无尽的慷慨滋养着万物</p>
                         <p>不断孕育出新的文明与希望</p>
                     </div>
                     <div className="brandPage__infoContent-text">
                         <p>我们尝试探寻一种永恒地能量</p>
                         <p>支撑我们拨开迷雾</p>
                     </div>
                      <div className="brandPage__infoContent-text">
                         <p>如末日深渊一抹闪烁的光亮</p>
                         <p>重生之念就此燎原</p>
                         <p>在万物复苏之际</p>
                         <p>我们感悟自然 拾取生息之壤</p>
                         <p>以诚挚之心打造</p>
                     </div>
                   </motion.div> 
                </div>
            </div>
            <div className="brandPage__footer">
                <div className="brandPage__footerContent">
                     <div className="brandPage__footerContent-text">
                         abcd啊啵呲嘚诞生于2023年6月，秉持着“引领新世代银饰浪潮”的愿景，创建了这个品牌品牌主张回归首饰的纯粹本质，融通艺术和潮流等文化元素，以独特设计塑造让人惊叹的银饰作品，迎接新世代的银饰浪潮。
                     </div>
                     <div className="brandPage__footerContent-text">
                         2023年11月，郑州正弘城店作为品牌首店正式开业，随后在杭州的银泰和万达，以及深圳的星河、卓悦汇、KALEDO、大仟里等商场陆续开设品牌门店。
                     </div>
                     <div className="brandPage__footerContent-text">
                         打破传统的品牌运营模式，abcd啊啵呲嘚着眼全球，创建了经验丰富的海外团队，拥有成熟的海外运营体系。国际物流服务覆盖全球各大洲和主要港口，并与国际知名支付公司合作，旨在提供安全便捷的品牌服务。原创产品畅销海外28个国家。丰富多样的品牌支持和产品服务，深受各国友人的喜爱，让世界看见了中国珠宝。
                     </div>
                </div>
               <img  src={logoImg} alt="" className="brandPage__footer-img"/>
            </div>
        </div>

    )
}

export default BrandPage;