import React from "react";
import './Products.less';

import ReactSlider from 'react-slider';

interface ProductsProps {
    data: Array < { name: string, pic: string, bigPic: string } > ;
    value: number;
    multiple: number;
}

export const Products: React.FC < ProductsProps > = (props) => {

    const { data, value, multiple } = props;

    const imgWidth = (window.innerWidth * 0.7 - 30) / 4;
    return (
        <div className="productList" style={{transform: `translateX(-${value * multiple }%)`}} >
          {
              data.map(item => {
                  return(
                  <div className="productItem">
                  <div className="productItem-name">[{item.name}]</div>
                     <img  
                       src={item.pic} 
                       alt="" 
                       className="productItem-img"
                       style={{ width: imgWidth, height: imgWidth}}
                      />
                       <img  
                       src={item.bigPic} 
                       alt="" 
                       className="productItem-bigImg"
                       style={{ width: imgWidth, height: imgWidth}}
                      />
                  </div>
                  )
              })
          }
          </div>
    )
}