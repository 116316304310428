import React, { useState, useEffect } from "react";
import './Header.less';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { relianItems, anyeItems, qianjiItems, yihuoItems, liehuangItems } from  '../../pages/Data';
import logoImg from "../../assets/common/logo.png";


export const Header: React.FC = () => {

    const [inputValue, setInputValue] = useState<string>('');
    // 路由相关
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // 在组件加载时以及每次路由变化时执行的逻辑
        const hash = window.location.hash;
        if (!hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> ) => {

       //console.log(e.target.value);
       const value = e.target.value;
       setInputValue(value.trim());
      
    }

    const handleSearchClick = () => {
       if(inputValue) {

          if('首页'.includes(inputValue)) {
             navigate('/');
             return;
          }
           const yihuoStr: string = yihuoItems.map(item => item.name).join(', ') + ' ,彝火生花' ;
          if(yihuoStr.includes(inputValue)) {
             navigate('/seriesYihuo');
             return;
          }

          const anyeStr: string = anyeItems.map(item => item.name).join(', ') + ' ,暗夜荆棘' ;
          if(anyeStr.includes(inputValue)) {
             navigate('/seriesAnye');
             return;
          }
           const liehuangStr: string = liehuangItems.map(item => item.name).join(', ') + ' ,猎荒派对' ;
          if(liehuangStr.includes(inputValue)) {
             navigate('/seriesLiehuang');
             return;
          }

           const relianStr: string = relianItems.map(item => item.name).join(', ') + ' ,热链玩家' ;
          if(relianStr.includes(inputValue)) {
             navigate('/seriesRelian');
             return;
          }

          const qianjiStr: string = qianjiItems.map(item => item.name).join(', ') + ' ,千机蝶变' ;
          if(qianjiStr.includes(inputValue)) {
             navigate('/seriesQianji');
             return;
          }

          navigate('/searchEmpty');
       }
    }

     const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.keyCode === 13) {
          handleSearchClick();
        }
     };

    return (
        <div className="headerContainer">
           <div className="header__row">
               <Link to="/" className="header__logo">
                  <img  src={logoImg} alt="" className="header__logo-img"/>
               </Link>
               <div className="header__menu">
                   <Link to="/brand" className="header__menuLink">品牌</Link>
                   <div className="header__menuLink link__series">
                   系列
                   <div className="header__menuSub">
                      <Link to="/seriesYihuo" className="header__menuSub-item">彝火生花</Link>
                      <Link to="/seriesAnye" className="header__menuSub-item">暗夜荆棘</Link>
                      <Link to="/seriesLiehuang" className="header__menuSub-item">猎荒派对</Link>
                      <Link to="/seriesRelian" className="header__menuSub-item">热链玩家</Link>
                      <Link to="/seriesQianji" className="header__menuSub-item">千机蝶变</Link>
                   </div>
                   </div>
                   <Link to="/shop" className="header__menuLink">门店</Link>
                   <Link to="/contact" className="header__menuLink">联系</Link>
               </div>
               <div className="header__search">
                   <input onChange={handleChange} placeholder="输入系列或产品名称"  onKeyDown={handleKeyDown} />
                   <a  className="header__search-btn"  onClick={handleSearchClick} ></a>
               </div>
           </div>
        </div>
    )
}