import React, { useEffect, useRef, useState } from 'react';
import "./Home.less";
import { Link } from "react-router-dom";
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import FourRowComponent from "./components/fourRow/FourRow";
import { Footer } from "../../components";
import mainBg from '../../assets/home/main_bg.jpg';
import avantImg from '../../assets/home/avant.png';
import arrowRightImg from '../../assets/home/arrow_right.png';

export const HomePage: React.FC = () => {

  const dispatch = useAppDispatch();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [controlsEnabled, setControlsEnabled] = useState(false);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        // 如果浏览器阻止了自动播放，可以在这里处理错误
        console.error('Auto play failed:', error);
        setControlsEnabled(true)
      });
    }
  };

  useEffect(() => {
    // 在组件挂载后开始播放视频
    playVideo();
    dispatch(
      globalSlice.actions.setGlobalSlice({
        headerIn: false
      })
    );
    //alert("屏幕宽度：" + window.innerWidth);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (scrollTop > 100) {
        dispatch(
          globalSlice.actions.setGlobalSlice({
            headerIn: true
          })
        );
      } else {
        dispatch(
          globalSlice.actions.setGlobalSlice({
            headerIn: false
          })
        );
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      dispatch(
        globalSlice.actions.setGlobalSlice({
          headerIn: true
        })
      );
    };
  }, []);

  return (
    <div className="homePage">
      {/* <img  src={mainBg} alt="" className="homePage__mainBg"/> */}
{      <div className="top-empty"></div>}
      <div className="content-row">
        <div className="homePage__mainWrap">
          <div className="homePage__avant">
              <img  src={avantImg} alt="" className="homePage__avant-img"/>
          </div>
          <Link to="/seriesLiehuang" className="homePage__videoWrap">
            <video
              ref={videoRef}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src="./main.mp4"
              controls={controlsEnabled}
              autoPlay loop muted playsInline x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" x5-playsinline="true"></video>
          </Link>
          <div className="homePage__solganRow">
             <Link to="/seriesLiehuang" className="homePage__solganLeft">
              <span className="homePage__solganLeft-b">猎荒派对</span>
              酷⼒觉醒 启程未来
              </Link>
             <Link to="/seriesLiehuang" className="homePage__solganRight">
              <span className="homePage__solganRight-span">即刻探索</span>
              <img  src={arrowRightImg} alt="" className="homePage__solganRight-img"/>
             </Link>
          </div>

           <FourRowComponent />

        </div>

      </div>
      <div className="homePage__footer">
         <Footer />
      </div>
      

    </div>
  );
};

export default HomePage;
