import React from "react";
import './Slider.less';

import ReactSlider from 'react-slider';

interface SliderProps {
    value: number;
    controlClass: string;
    onchange: (s: number, index: number) => void;

}

export const Slider : React.FC < SliderProps > = (props) => {

   const { value, controlClass, onchange } = props;
   
   const handleOnchange = (s: any, index: number) => {
        onchange(s, index);
    }

    return (
        <div className="SliderContainer">
             <ReactSlider
                          className="horizontal-slider"
                          thumbClassName={`slider-thumb ${controlClass}`}
                          trackClassName="slider-track"
                          value={value}
                          onChange={handleOnchange}
                        />
        </div>
    )
}